import { fromJS, Map } from "immutable";

import logger from "Libs/logger";

const LOAD_VARIABLES_START = "app/projectVariables/load_variables_start";
const LOAD_VARIABLES_SUCCESS = "app/projectVariables/load_variables_success";
const LOAD_VARIABLES_FAILURE = "app/projectVariables/load_variables_failure";

const UPDATE_VARIABLE_START = "app/projectVariable/update_variable_start";
const UPDATE_VARIABLE_SUCCESS = "app/projectVariable/update_variable_success";
const UPDATE_VARIABLE_FAILURE = "app/projectVariable/update_variable_failure";
const UPDATE_VARIABLE_CANCEL = "app/projectVariable/update_variable_cancel";

const DELETE_VARIABLE_START = "app/projectVariable/delete_variable_start";
const DELETE_VARIABLE_SUCCESS = "app/projectVariable/delete_variable_success";
const DELETE_VARIABLE_FAILURE = "app/projectVariable/delete_variable_failure";

const EDIT_LINE = "app/projectVariable/edit_line";

export const editLine = (index, isNew) => {
  return {
    type: EDIT_LINE,
    payload: index,
    meta: { isNew }
  };
};

export const cancelUpdateVariable = () => ({ type: UPDATE_VARIABLE_CANCEL });

export const deleteVariable = (
  organizationDescriptionId,
  projectDescriptionId,
  variable
) => {
  return async dispatch => {
    if (!variable) {
      return false;
    }

    dispatch({ type: DELETE_VARIABLE_START });

    try {
      await variable.delete();

      dispatch({
        type: DELETE_VARIABLE_SUCCESS,
        payload: variable,
        meta: { organizationDescriptionId, projectDescriptionId }
      });
    } catch (err) {
      logger(err, {
        action: "project_delete_variable",
        payload: variable,
        meta: { organizationDescriptionId, projectDescriptionId }
      });
      dispatch({
        type: DELETE_VARIABLE_FAILURE,
        payload: err.detail || err,
        error: true
      });
    }
  };
};

export const loadVariables = (organizationDescriptionId, project) => {
  return async dispatch => {
    if (!project) {
      return false;
    }

    dispatch({ type: LOAD_VARIABLES_START });

    try {
      const projectVariables = await project.getVariables();

      dispatch({
        type: LOAD_VARIABLES_SUCCESS,
        payload: {
          projectVariables
        },
        meta: {
          organizationDescriptionId,
          projectDescriptionId: project.id
        }
      });
    } catch (err) {
      logger(err, {
        action: "project_load_variables",
        project,
        meta: {
          organizationDescriptionId,
          projectDescriptionId: project.id
        }
      });
      dispatch({ type: LOAD_VARIABLES_FAILURE, error: true, payload: err });
    }
  };
};

export const updateVariable = (
  organizationDescriptionId,
  project,
  variable,
  editedLine,
  isNew
) => {
  return async dispatch => {
    if (!project) {
      return false;
    }

    dispatch({ type: UPDATE_VARIABLE_START });

    try {
      const result = await project.setVariable(
        variable.name,
        variable.value,
        variable.is_json,
        variable.is_sensitive,
        variable.visible_build,
        variable.visible_runtime
      );

      if ((result.data && result.data.code / 100) === 4) {
        return dispatch({
          type: UPDATE_VARIABLE_FAILURE,
          payload: result.detail,
          meta: { editedLine, isNew }
        });
      }

      const newVariable = await result.getEntity();

      dispatch({
        type: UPDATE_VARIABLE_SUCCESS,
        payload: newVariable,
        meta: {
          organizationDescriptionId,
          projectDescriptionId: project.id
        }
      });
    } catch (err) {
      logger(err, {
        action: "project_update_variable",
        project,
        meta: {
          organizationDescriptionId,
          projectDescriptionId: project.id
        },
        editedLine,
        isNew
      });

      dispatch({
        type: UPDATE_VARIABLE_FAILURE,
        payload: err.detail,
        meta: { editedLine, isNew }
      });
    }
  };
};

export default function projectVariableReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_VARIABLES_START:
      return state.set("loading", true).set("errors", new Map());
    case UPDATE_VARIABLE_START:
      return state.set("updateLoading", true).set("errors", new Map());
    case EDIT_LINE:
      return state
        .set("editedLine", action.payload)
        .set("isNew", action.meta.isNew);
    case UPDATE_VARIABLE_CANCEL:
      return state.set("editedLine", false).set("isNew", false);
    case UPDATE_VARIABLE_SUCCESS:
      return state
        .setIn(
          [
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.payload.id
          ],
          fromJS(action.payload)
        )
        .set("editedLine", false)
        .set("isNew", false)
        .set("updateLoading", false);
    case DELETE_VARIABLE_SUCCESS:
      return state.deleteIn([
        "data",
        action.meta.organizationDescriptionId,
        action.meta.projectDescriptionId,
        action.payload.id
      ]);
    case LOAD_VARIABLES_SUCCESS:
      return state.set("loading", false).set(
        "data",
        action.payload.projectVariables.reduce(
          (organizationsProjects, variable) => {
            return organizationsProjects.setIn(
              [
                action.meta.organizationDescriptionId,
                action.meta.projectDescriptionId,
                variable.id
              ],
              variable
            );
          },
          new Map()
        )
      );

    case LOAD_VARIABLES_FAILURE:
      return state.set("loading", false).set("errors", fromJS(action.payload));
    case UPDATE_VARIABLE_FAILURE:
      return state
        .set("updateLoading", false)
        .set("errors", fromJS(action.payload));
    default:
      return state;
  }
}
